// src/components/Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#16a085] text-white py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="text-left">
          <h2 className="text-xl font-bold">MBS Yogyakarta</h2>
        </div>
        <div className="text-right">
          <p className="text-sm">© 2024 PrabuBima. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
