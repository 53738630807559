import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ImageApp } from "../assets";
import { useAuth } from "../context/AuthContext";

const Navbar = () => {
  const { logout } = useAuth();
  const [showNavbar, setShowNavbar] = React.useState(false);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);

  const getSaldo = async () => {
    const username = localStorage.getItem("username");
    const api_key = localStorage.getItem("api_key");
    // const url = `v1/uangsaku/saldo?nis=${username}&device_id=${username}`;
    const url = `https://ortuapi.mbsyogyakarta.org/v1/uangsaku/saldo?nis=${username}&device_id=${username}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${api_key}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setdata(data.data);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  useEffect(() => {
    getSaldo();
    // const apiKey = localStorage.getItem("api_key");
    // if (apiKey) {
    //   setIsAuthenticated(true);
    // }
  }, []);

  const navigateprofile = () => {
    navigate("/profile");
  };

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleLogout = () => {
    localStorage.removeItem("api_key"); // Hapus api_key dari local storage
    logout(); // Panggil fungsi logout dari AuthContext
  };

  return (
    <nav className="bg-[#16a085] flex items-center">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        {/* Left: Logo */}
        <div className="flex items-center">
          {/* <div className="flex-shrink-0 flex items-stretch"> */}
          <button onClick={navigateprofile}>
            <img
              className="h-16 w-auto"
              src={ImageApp}
              alt="Bank Mini Santri - MBS Yogyakarta"
            />
          </button>
          <div>
            <h2 className="text-base font-bold leading-9 tracking-tight text-white ml-4">
              {data.nama}
            </h2>
            <h2 className="text-base font-bold leading-9 tracking-tight text-white ml-4">
              Rp. {data.saldo}
            </h2>
          </div>
        </div>

        {/* Center: Menu */}
        <div className="hidden sm:flex flex-grow justify-center">
          <ul className="flex space-x-10">
            {/* <li>
<Link
to="/home"
exact
className="text-lg text-white hover:text-[#fdcb6e]"
activeClassName="font-semibold text-[#fdcb6e]"
>
Home
</Link>
</li>

<li>
<Link
to="/cara-bayar"
className="text-lg text-white hover:text-[#fdcb6e]"
activeClassName="font-semibold text-[#fdcb6e]"
>
Cara Bayar Tagihan
</Link>
</li> */}
          </ul>
        </div>

        {/* Right: Login Button */}
        <div className="hidden sm:block">
          <button
            onClick={handleLogout}
            className="bg-[#fdcb6e] text-black px-4 py-2 rounded w-full"
          >
            Logout
          </button>
        </div>

        {/* Hamburger Menu for Mobile */}
        <div
          className="block sm:hidden cursor-pointer"
          onClick={handleShowNavbar}
        >
          <Hamburger />
          {/* <button
            onClick={handleLogout}
            className="bg-[#fdcb6e] text-black px-4 py-2 rounded w-full"
          >
            Logout
          </button> */}
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`nav-elements ${
          showNavbar ? "block" : "hidden"
        } sm:hidden absolute right-0 top-20 bg-[#16a085] w-full h-auto transition-all duration-300 ease-in`}
      >
        <ul className="flex flex-col space-y-6 p-4">
          <li>
            <Link
              to="/home"
              exact
              className="text-lg text-white hover:text-[#fdcb6e]"
              activeClassName="font-semibold text-gray-700"
            >
              Home
            </Link>
          </li>
          {/* <li>
            <Link
              to="/cara-bayar"
              className="text-lg text-white hover:text-[#fdcb6e]"
              activeClassName="font-semibold text-gray-700"
            >
              Cara Bayar Tagihan
            </Link>
          </li> */}

          <li>
            <button
              onClick={handleLogout}
              className="bg-[#fdcb6e] text-black px-4 py-2 rounded w-full"
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 47)"
        fill="#ffff"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 67)"
        fill="#ffff"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="52"
        height="4"
        rx="2"
        transform="translate(294 57)"
        fill="#ffff"
      />
    </g>
  </svg>
);

export default Navbar;
