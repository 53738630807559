import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  IconCaraBayarTagihan,
  IconHelp,
  IconHistoryTagihan,
  IconHistoryTopUpUangSaku,
  IconTagihan,
  IconTopUpUangSaku,
} from "../assets";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Navbar />

      <div className="App flex-grow">
        {/* <div className="flex flex-row items-center p-4">
          <img
            className="h-12 mr-4"
            src={ImageApp}
            alt="Bank Mini Santri - MBS Yogyakarta"
          />
          <h2 className="text-base font-bold leading-9 tracking-tight text-gray-900">
            Nova Fulan
          </h2>
        </div> */}

        <div className="w-2/3 mx-auto m-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Link to="/uang-saku" exact>
              <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <img
                  src={IconCaraBayarTagihan}
                  alt="Uang Saku"
                  className="h-24 w-24 mr-2"
                />
                Cara TopUp Uang Saku
              </button>
            </Link>

            {/* <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <img
                src={IconCaraBayarTagihan}
                alt="Cara Bayar"
                className="h-24 w-24 mr-2"
              />
              Cara TopUp Uang Saku
            </button> */}
            <Link to="/riwayatuangsaku" exact>
              <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <img
                  src={IconHistoryTopUpUangSaku}
                  alt="Top Up"
                  className="h-24 w-24 mr-2"
                />
                Riwayat Uang Saku
              </button>
            </Link>

            <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <img src={IconTagihan} alt="Tagihan" className="h-24 w-24 mr-2" />
              Biaya Per Tahun
            </button>
            <Link to="/riwayat-tagihan" exact>
              <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <img
                  src={IconHistoryTagihan}
                  alt="History Tagihan"
                  className="h-24 w-24 mr-2"
                />
                Riwayat Tagihan
              </button>
            </Link>

            {/* <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <img
                src={IconTopUpUangSaku}
                alt="Top Up Uangsaku"
                className="h-24 w-24 mr-2"
              />
              Cara Bayar Tagihan
            </button> */}

            <Link to="/cara-bayar" exact>
              <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <img
                  src={IconTopUpUangSaku}
                  alt="Top Up Uangsaku"
                  className="h-24 w-24 mr-2"
                />
                Cara Bayar Tagihan
              </button>
            </Link>

            {/* <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <img src={IconHelp} alt="Help" className="h-24 w-24 mr-2" />
              Pusat Bantuan
            </button> */}

            <Link to="/pusat-bantuan" exact>
              <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <img src={IconHelp} alt="Help" className="h-24 w-24 mr-2" />
                Pusat Bantuan
              </button>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
