import React from "react";
import { Helmet } from "react-helmet";

import { LogoMuamalat, BankLain } from "../assets";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const CaraBayar = () => {
  return (
    <div>
      <Helmet>
        <title>Cara TopUp Uangsaku</title>
      </Helmet>

      <Navbar />

      <div className="w-3/2 mx-auto m-6 p-4 lg:p-0">
        <div className="flex flex-wrap justify-center items-stretch gap-6">
          <div className="max-w-sm w-full rounded overflow-hidden shadow-lg flex flex-col">
            <img
              className="w-full h-32 object-cover bg-[#e9e3ef] p-4"
              src={LogoMuamalat}
              alt="Logo Bank Muamalat"
            />
            <div className="px-6 py-4 flex-grow">
              <div className="font-bold text-xl mb-2">
                MOBILE BANKING BANK MUAMALAT (MUAMALAT DIN)
              </div>

              <ol className="list-decimal m-4">
                <li className="text-gray-700 text-base">
                  Login ke Muamalat DIN
                </li>
                <li className="text-gray-700 text-base">
                  Pilih menu "Beli atau Bayar"
                </li>
                <li className="text-gray-700 text-base">
                  Pilih menu "Virtual Account"
                </li>
                <li className="text-gray-700 text-base">
                  Masukan nomor "Virtual Account". Contoh: 8573801 + NIS.
                  Setelah itu klik lanjut
                </li>
                <li className="text-gray-700 text-base">
                  Cek nama siswa dan jumlah tagihan selanjutnya masukan TIN
                  Muamalat DIN
                </li>
                <li className="text-gray-700 text-base">
                  Simpan bukti transaksi sebagai bukti pembayaran
                </li>
              </ol>
            </div>
          </div>

          <div className="max-w-sm w-full rounded overflow-hidden shadow-lg flex flex-col">
            <img
              className="w-full h-32 object-cover bg-[#e9e3ef] p-4"
              src={LogoMuamalat}
              alt="Logo Bank Muamalat"
            />
            <div className="px-6 py-4 flex-grow">
              <div className="font-bold text-xl mb-2">ATM BANK MUAMALAT</div>

              <ol className="list-decimal m-4">
                <li className="text-gray-700 text-base">
                  Masukan kartu ATM, lalu tuliskan PIN Anda
                </li>
                <li className="text-gray-700 text-base">
                  Pilih menu "Transaksi lain"
                </li>
                <li className="text-gray-700 text-base">
                  Pilih menu "Pembayaran" lalu pilih menu "Virtual Account"
                </li>
                <li className="text-gray-700 text-base">
                  Masukan nomor "Virtual Account". Contoh: 8573801 + NIS
                </li>
                <li className="text-gray-700 text-base">
                  Tekan "YA" jika setuju dengan informasi pembayaran
                </li>
                <li className="text-gray-700 text-base">
                  Kemudian tekan "BENAR"
                </li>
                <li className="text-gray-700 text-base">
                  Simpan bukti transaksi / struk ATM sebagai bukti pembayaran
                </li>
              </ol>
            </div>
          </div>

          <div className="max-w-sm w-full rounded overflow-hidden shadow-lg flex flex-col">
            <img
              className="w-full h-32 object-cover"
              src={BankLain}
              alt="Bank Lain"
            />
            <div className="px-6 py-4 flex-grow">
              <div className="font-bold text-xl mb-2">
                MOBILE BANKING BANK LAIN
              </div>

              <ol className="list-decimal m-4">
                <li className="text-gray-700 text-base">
                  Login ke Mobile Banking
                </li>
                <li className="text-gray-700 text-base">
                  Pilih menu "Transfer"
                </li>
                <li className="text-gray-700 text-base">
                  Masukan nama Bank tujuan: Bank Muamalat Indonesia
                </li>
                <li className="text-gray-700 text-base">
                  Masukan nomor "Rekening". Contoh: 8573801 + NIS. Setelah itu
                  klik lanjut
                </li>
                <li className="text-gray-700 text-base">Cek Nama Siswa</li>
                <li className="text-gray-700 text-base">
                  Masukan nominal jumlah tagihan + Rp. 2.000 (Misal: Tagihan Rp.
                  200.000, maka dimasukan nominal Rp. 202.000)
                </li>
                <li className="text-gray-700 text-base">
                  Pilih metode transfer BI Fast (Biaya 2.500)
                </li>
                <li className="text-gray-700 text-base">Masukan PIN</li>
                <li className="text-gray-700 text-base">
                  Simpan bukti transaksi sebagai bukti pembayaran
                </li>
              </ol>
            </div>
          </div>

          <div className="max-w-sm w-full rounded overflow-hidden shadow-lg flex flex-col">
            <img
              className="w-full h-32 object-cover"
              src={BankLain}
              alt="Bank Lain"
            />
            <div className="px-6 py-4 flex-grow">
              <div className="font-bold text-xl mb-2">
                MELALUI ATM BANK LAIN
              </div>

              <ol className="list-decimal m-4">
                <li className="text-gray-700 text-base">
                  Masukan kartu ATM, lalu tuliskan PIN Anda
                </li>
                <li className="text-gray-700 text-base">
                  Pilih menu "Transfer"
                </li>
                <li className="text-gray-700 text-base">
                  "Transfer antar Bank" (Bank Muamalat Indonesia – Kode 147)
                </li>
                <li className="text-gray-700 text-base">
                  Masukan nomor "Rekening" dan kode Bank. Contoh: 147 8573801 +
                  NIS
                </li>
                <li className="text-gray-700 text-base">
                  Masukan nominal sesuia tagihan + fee adm Rp 2.000 (Misal:
                  Tagihan Rp. 200.000, maka dimasukan nominal Rp 202.000)
                </li>
                <li className="text-gray-700 text-base">
                  Tekan "YA" jika setuju dengan informasi pembayaran
                </li>
                <li className="text-gray-700 text-base">
                  Kemudian tekan "BENAR"
                </li>
                <li className="text-gray-700 text-base">
                  Simpan bukti transaksi / struk ATM sebagai bukti pembayaran
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CaraBayar;
