import React from "react";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

const Profile = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <div className="App flex-grow">
        <div className="w-2/3 mx-auto m-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Link to="/informasiakun" exact>
              <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Informasi Akun
              </button>
            </Link>
            <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Edit Profile
            </button>

            <button className="max-w-sw w-full flex my-0.5 items-center justify-center rounded-md bg-green-600 px-2 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Edit Password
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Profile;
