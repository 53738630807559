import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const apiKey = localStorage.getItem("api_key");
    if (apiKey) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (username, password, device_id) => {
    const url = "https://ortuapi.mbsyogyakarta.org/v1/auth/login";
    // const url = "/v1/auth/login";

    const body = {
      username: username,
      password: password,
      device_id: device_id,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Login successful:", data);
      setIsAuthenticated(true);
      localStorage.setItem("api_key", data.api_key);
      localStorage.setItem("username", username);
    } catch (error) {
      console.error("Error during login:", error);
      setIsAuthenticated(false);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
