import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/Navbar";

const InformasiAkun = () => {
  const [data, setdata] = useState([]);

  const getinformasiakun = async () => {
    const username = localStorage.getItem("username");
    const api_key = localStorage.getItem("api_key");
    // const url = `v1/siswa/biodata?nis=${username}`;
    const url = `https://ortuapi.mbsyogyakarta.org/v1/siswa/biodata?nis=${username}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${api_key}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      setdata(data.data);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  useEffect(() => {
    getinformasiakun();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Cara Bayar Tagihan</title>
      </Helmet>

      <Navbar />

      <div className="w-3/2 mx-auto m-6 p-4 lg:p-0">
        <div className="flex flex-wrap justify-center items-stretch">
          <div className="w-full h-full rounded overflow-hidden shadow-lg flex flex-col">
            <div className="px-6 py-4 flex-grow">
              <div className="font-bold text-xl mb-2">Informasi Akun</div>

              <ol className="list-decimal">
                <h1 className="text-gray-700 text-base">
                  Nama : {data?.biodata?.nama}
                </h1>
                <h1 className="text-gray-700 text-base">
                  NIS : {data?.biodata?.nis}
                </h1>
                <h1 className="text-gray-700 text-base">
                  No Hp : {data?.biodata?.notelpon}
                </h1>
                <h1 className="text-gray-700 text-base">
                  Nama Panggilan : {data?.biodata?.panggilan}
                </h1>
                <h1 className="text-gray-700 text-base">
                  Tempat Lahir : {data?.biodata?.tempatlahir}
                </h1>
                <h1 className="text-gray-700 text-base">
                  Tanggal Lahir : {data?.biodata?.tgllahir}
                </h1>
                <h1 className="text-gray-700 text-base">
                  Alamat : {data?.biodata?.alamat}
                </h1>
              </ol>

              <div className="font-bold text-xl mb-2 mt-2">Riwayat Kelas</div>

              <ol className="list-decimal">
                {data?.historyKelas?.map((data) => {
                  return (
                    <div className="mb-2">
                      <h1 className="text-gray-700 text-base">
                        Nama Tahun : {data?.namata}
                      </h1>
                      <h1 className="text-gray-700 text-base">
                        Nama Jenjang : {data?.namajenjang}
                      </h1>
                      <h1 className="text-gray-700 text-base">
                        Nama Kelas : {data?.namakelas}
                      </h1>
                    </div>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformasiAkun;
