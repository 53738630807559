import React from "react";

const Contact = () => {
  return (
    <div>
      <h2>Contact</h2>
      <p>This is the Contact page.</p>
    </div>
  );
};

export default Contact;
