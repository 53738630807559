import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/Navbar";

const RiwayatTagihan = () => {
  const [data, setdata] = useState([]);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var akhir = yyyy + "-" + mm + "-" + dd;

  const getriwayatuangsaku = async () => {
    const username = localStorage.getItem("username");
    const api_key = localStorage.getItem("api_key");
    // const url = `v1/tagihan/list?nis=${username}&device_id=${username}`;
    const url = `https://ortuapi.mbsyogyakarta.org/v1/tagihan/list?nis=${username}&device_id=${username}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${api_key}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      setdata(data.data);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  useEffect(() => {
    getriwayatuangsaku();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Cara Bayar Tagihan</title>
      </Helmet>

      <Navbar />

      <div className="w-3/2 mx-auto m-6 p-4 lg:p-0">
        <div className="flex flex-wrap justify-center items-stretch">
          <div className="w-full h-full rounded overflow-hidden shadow-lg flex flex-col">
            <div className="px-6 py-4 flex-grow">
              <div className="font-bold text-xl mb-2">Riwayat Tagihan</div>

              <ol className="list-decimal">
                {data.map((data) => {
                  return (
                    <div className="p-2 bg-teal-200 rounded-xl mb-4">
                      <h1 className="text-gray-700 text-base">
                        Waktu Transaksi : {data?.waktutransaksi}
                      </h1>
                      <h1 className="text-gray-700 text-base">
                        Total Tagihan : {data?.totaltagihan}
                      </h1>
                    </div>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiwayatTagihan;
