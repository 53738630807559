import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import CaraBayar from "./pages/CaraBayar";
import Uangsaku from "./pages/Uangsaku";
import Profile from "./pages/Profile";
import PusatBantuan from "./pages/PusatBantuan";

import { AuthProvider, useAuth } from "./context/AuthContext";
import InformasiAkun from "./pages/InformasiAkun";
import RiwayatUangSaku from "./pages/RiwayatUangSaku";
import RiwayatTagihan from "./pages/RiwayatTagihan";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
          <Route
            path="/about-us"
            element={<ProtectedRoute element={<AboutUs />} />}
          />
          <Route
            path="/contact"
            element={<ProtectedRoute element={<Contact />} />}
          />
          <Route
            path="/cara-bayar"
            element={<ProtectedRoute element={<CaraBayar />} />}
          />
          <Route
            path="/uang-saku"
            element={<ProtectedRoute element={<Uangsaku />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path="/informasiakun"
            element={<ProtectedRoute element={<InformasiAkun />} />}
          />
          <Route
            path="/riwayatuangsaku"
            element={<ProtectedRoute element={<RiwayatUangSaku />} />}
          />
          <Route
            path="/pusat-bantuan"
            element={<ProtectedRoute element={<PusatBantuan />} />}
          />
          <Route
            path="/riwayat-tagihan"
            element={<ProtectedRoute element={<RiwayatTagihan />} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
